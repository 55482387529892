import { createWebHistory, createRouter } from "vue-router";
import IndexView from "@/components/IndexView";
const routes = [
    {
        path: "/",
        name: "Home",
        component: IndexView,
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

export default router;
