<template>
  <div>
    <div v-if="loader">
      <div class="loading">Завантаження</div>
      <br>
      <b>Завантаження локацій</b>
    </div>
    <div v-if="!loader">
      <div v-bind:style="{'opacity': searchLoader ? '0.5': '1'}">
        <input v-model="searchValue" style="position:absolute;top:55px;left:10px;z-index:9999;" type="text" placeholder="Пошук"/>
        <button :disabled="searchLoader" @click="makeSearch" style="position:absolute;top:55px;left:220px;z-index:9999;">Знайти</button>
      </div>
      <div>
        <GoogleMap api-key="AIzaSyBP_mrC_0nacccDHjP0IGi8EUxYmHQgFx0"
                   style="width: 100%; height: 100%;position:absolute;top: 0px;left: 0px;" :center="center" :zoom="15"
                   ref="myMapRef"
                   :styles="styles">
          <MarkerCluster v-if="markers.length > 0">
            <Marker :options="{ position: marker.position, icon: marker.icon }" v-for="marker in markers"
                    v-bind:key="marker.id">
              <InfoWindow>
                <div v-html="marker.info"/>
              </InfoWindow>
            </Marker>
          </MarkerCluster>
          <Polyline :options="line.options" v-for="line in lines" v-bind:key="line.id" @click="log(line)"/>
          <Marker
              v-for="restaurant in restaurants"
              :key="restaurant.place_id"
              :options="{ position: restaurant.geometry.location, icon: '/rest.png' }">
            <InfoWindow>
              <div v-html="`<b>${restaurant.name}</b><br/>${restaurant.formatted_address}</br><a target='_blank' href='https://www.google.com/maps/place/?q=place_id:${restaurant.place_id}'>Посилання</a>`"/>
            </InfoWindow>
          </Marker>
        </GoogleMap>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue'
import {GoogleMap, Marker, InfoWindow, Polyline, MarkerCluster} from "vue3-google-map";

// Declare google as a global variable
/* global google */

export default {
  name: 'IndexView',
  components: {GoogleMap, Marker, InfoWindow, Polyline, MarkerCluster},
  data() {
    return {
      markers: [],
      ways: [],
      lines: [],
      itemCount: 0,
      currentItemCount: 0,
      loader: true,
    }
  },
  setup() {

    const myMapRef = ref(null);
    const restaurants = ref([]);
    let uniqueIds = [];
    const searchValue = ref("");
    const searchLoader = ref(false);

    const center = {
      lat: 50.468464708691506,
      lng: 30.470504964672728,
      zoom: 3,
      zoomControl: false,
      mapTypeControl: false,
      streetViewControl: false,
    };
    const styles = [
      {elementType: "geometry", stylers: [{color: "#242f3e"}]},
      {elementType: "labels.text.stroke", stylers: [{color: "#242f3e"}]},
      {elementType: "labels.text.fill", stylers: [{color: "#746855"}]},
      {
        featureType: "administrative.locality",
        elementType: "labels.text.fill",
        stylers: [{color: "#d59563"}],
      },
      {
        featureType: "poi",
        elementType: "labels.text.fill",
        stylers: [{color: "#d59563"}],
      },
      {
        featureType: "poi.park",
        elementType: "geometry",
        stylers: [{color: "#263c3f"}],
      },
      {
        featureType: "poi.park",
        elementType: "labels.text.fill",
        stylers: [{color: "#6b9a76"}],
      },
      {
        featureType: "road",
        elementType: "geometry",
        stylers: [{color: "#38414e"}],
      },
      {
        featureType: "road",
        elementType: "geometry.stroke",
        stylers: [{color: "#212a37"}],
      },
      {
        featureType: "road",
        elementType: "labels.text.fill",
        stylers: [{color: "#9ca5b3"}],
      },
      {
        featureType: "road.highway",
        elementType: "geometry",
        stylers: [{color: "#746855"}],
      },
      {
        featureType: "road.highway",
        elementType: "geometry.stroke",
        stylers: [{color: "#1f2835"}],
      },
      {
        featureType: "road.highway",
        elementType: "labels.text.fill",
        stylers: [{color: "#f3d19c"}],
      },
      {
        featureType: "transit",
        elementType: "geometry",
        stylers: [{color: "#2f3948"}],
      },
      {
        featureType: "transit.station",
        elementType: "labels.text.fill",
        stylers: [{color: "#d59563"}],
      },
      {
        featureType: "water",
        elementType: "geometry",
        stylers: [{color: "#17263c"}],
      },
      {
        featureType: "water",
        elementType: "labels.text.fill",
        stylers: [{color: "#515c6d"}],
      },
      {
        featureType: "water",
        elementType: "labels.text.stroke",
        stylers: [{color: "#17263c"}],
      },
      {
        featureType: "poi.attraction",
        stylers: [{visibility: 'off'}],
      },
      {
        featureType: "poi.government",
        stylers: [{visibility: 'off'}],
      },
      {
        featureType: "poi.medical",
        stylers: [{visibility: 'off'}],
      },
      {
        featureType: "poi.park",
        stylers: [{visibility: 'off'}],
      },
      {
        featureType: "poi.place_of_worship",
        stylers: [{visibility: 'off'}],
      },
      {
        featureType: "poi.school",
        stylers: [{visibility: 'off'}],
      },
      {
        featureType: "poi.sports_complex",
        stylers: [{visibility: 'off'}],
      },
      {
        featureType: "poi.business",
        stylers: [{visibility: 'off'}],
      },
    ];

    const makeSearch = () => {

      uniqueIds = [];
      restaurants.value = [];

      searchLoader.value = true;

      const radiusVariant = [
          1000,
          3000,
          5000,
          8000,
          10000,
          15000,
          25000,
          30000,
          40000,
          50000
      ];

      const service = new google.maps.places.PlacesService(myMapRef.value.map);

      radiusVariant.forEach((radius) => {
        service.textSearch({
          query: searchValue.value,
          location: myMapRef.value.map.getCenter(),
          radius: radius,
        }, (results, status) => {
          if (status === google.maps.places.PlacesServiceStatus.OK) {
            results.forEach((result) => {
              if(!uniqueIds.includes(result.place_id)) {
                uniqueIds.push(result.place_id);
                restaurants.value.push(result);
              }
            })
          }
        });
        searchLoader.value = false;
      });

    }

    return {center, styles, myMapRef, restaurants, makeSearch, searchValue, searchLoader};
  },
  mounted() {

    // First Query

    let page = 1;
    let take = 100;

    this.$http.get(`${process.env.VUE_APP_API_URL}/street/with-ways?page=${page}&take=${take}`, {
      headers: {
        Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VybmFtZSI6ImFkbWluQGFkbWluLnJvb3QiLCJzdWIiOiIzZGUzNDVjMC1iYzM3LTQ2MTUtYTVhNS01YmRmNjkwMTE4ZDAiLCJpYXQiOjE2NzAyNTYwMTN9.Ll2QY9dBDyBmyUCBLHQ5bNVSAJ1vx_b28J01ugN2p2c`
      },
      data: {
        page: page,
        take: take,
      }
    }).then((response) => {
      const parsedResponse = response.data;
      const meta = parsedResponse.meta;
      const pageCount = meta.pageCount;
      this.itemCount = meta.itemCount;

      const range = Array.from({length: pageCount}, (x, i) => i);
      range.forEach((page) => {
        if(page > 0) {
          this.$http.get(`${process.env.VUE_APP_API_URL}/street/with-ways?page=${page}&take=${take}`, {
            headers: {
              Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VybmFtZSI6ImFkbWluQGFkbWluLnJvb3QiLCJzdWIiOiIzZGUzNDVjMC1iYzM3LTQ2MTUtYTVhNS01YmRmNjkwMTE4ZDAiLCJpYXQiOjE2NzAyNTYwMTN9.Ll2QY9dBDyBmyUCBLHQ5bNVSAJ1vx_b28J01ugN2p2c`
            },
            data: {
              page: page,
              take: take,
            }
          }).then((response) => {
            const parsedResponse = response.data;
            const data = parsedResponse.data;
            data.forEach((street) => {

              let icon;
              let locations = street.locations;
              let isHaveActiveLocation = false;

              locations.forEach((location) => {
                if (location.is_active) {
                  icon = '/status/green.png';
                  isHaveActiveLocation = true;
                } else {
                  icon = '/status/yellow.png'
                }

                this.markers.push({
                  id: location.id,
                  position:
                      {
                        lat: parseFloat(location.latitude),
                        lng: parseFloat(location.longitude)
                      },
                  icon: icon,
                  info: `${location.title}<br>${location.address_title}`,
                });

              });


              if (street.ways) {
                const ways = street.ways;
                ways.forEach((way) => {
                  const coords = way.coords;
                  if (way.coords) {
                    const lineLocal = [];
                    coords.forEach((coord) => {
                      lineLocal.push({
                        lat: parseFloat(coord.latitude),
                        lng: parseFloat(coord.longitude),
                      })
                    });
                    if (isHaveActiveLocation) {
                      const lineObj = {
                        path: lineLocal,
                        geodesic: true,
                        strokeColor: "#32e513",
                        strokeOpacity: 0.8,
                        strokeWeight: 20,
                      };
                      this.lines.push({
                        options: lineObj,
                        id: street.id,
                      });
                    } else {
                      const lineObj = {
                        path: lineLocal,
                        geodesic: true,
                        strokeColor: "#e5bb13",
                        strokeOpacity: 0.2,
                        strokeWeight: 20,
                      };
                      this.lines.push({
                        options: lineObj,
                        id: street.id,
                      });
                    }
                  }
                });
              }

              this.currentItemCount = this.itemCount + take;

            });

            setTimeout(() => {
              this.loader = false;
            }, 5000);
          });
        }
      });
    });
  },
  methods: {
    log: function (line) {
      console.log(line);
    },
  }
};
</script>

<style>
/* Absolute Center Spinner */
.loading {
  position: fixed;
  z-index: 999;
  height: 2em;
  width: 2em;
  overflow: show;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

/* Transparent Overlay */
.loading:before {
  content: '';
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: radial-gradient(rgba(20, 20, 20,.8), rgba(0, 0, 0, .8));

  background: -webkit-radial-gradient(rgba(20, 20, 20,.8), rgba(0, 0, 0,.8));
}

/* :not(:required) hides these rules from IE9 and below */
.loading:not(:required) {
  /* hide "loading..." text */
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.loading:not(:required):after {
  content: '';
  display: block;
  font-size: 10px;
  width: 1em;
  height: 1em;
  margin-top: -0.5em;
  -webkit-animation: spinner 150ms infinite linear;
  -moz-animation: spinner 150ms infinite linear;
  -ms-animation: spinner 150ms infinite linear;
  -o-animation: spinner 150ms infinite linear;
  animation: spinner 150ms infinite linear;
  border-radius: 0.5em;
  -webkit-box-shadow: rgba(255,255,255, 0.75) 1.5em 0 0 0, rgba(255,255,255, 0.75) 1.1em 1.1em 0 0, rgba(255,255,255, 0.75) 0 1.5em 0 0, rgba(255,255,255, 0.75) -1.1em 1.1em 0 0, rgba(255,255,255, 0.75) -1.5em 0 0 0, rgba(255,255,255, 0.75) -1.1em -1.1em 0 0, rgba(255,255,255, 0.75) 0 -1.5em 0 0, rgba(255,255,255, 0.75) 1.1em -1.1em 0 0;
  box-shadow: rgba(255,255,255, 0.75) 1.5em 0 0 0, rgba(255,255,255, 0.75) 1.1em 1.1em 0 0, rgba(255,255,255, 0.75) 0 1.5em 0 0, rgba(255,255,255, 0.75) -1.1em 1.1em 0 0, rgba(255,255,255, 0.75) -1.5em 0 0 0, rgba(255,255,255, 0.75) -1.1em -1.1em 0 0, rgba(255,255,255, 0.75) 0 -1.5em 0 0, rgba(255,255,255, 0.75) 1.1em -1.1em 0 0;
}

/* Animation */

@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-moz-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-o-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
</style>
